import {
  UndefinedInitialDataOptions,
  queryOptions,
  useQuery,
} from "@tanstack/react-query";
import { client } from "@utils/axios-utils";

export interface GetEmbeddedUrlResponse {
  requested_on: string;
  data: {
    url: string;
  };
}

export interface GetEmbeddedUrlRequest {
  content_id: string | string[];
}

export const getEmbeddedUrl = async ({ content_id }: GetEmbeddedUrlRequest) => {
  const response = await client().get<GetEmbeddedUrlResponse>(
    "v2/creators/analytics/embed",
    {
      params: {
        content_id:
          typeof content_id === "string" ? content_id : content_id.join(","),
      },
    },
  );

  return response.data;
};

export const GetUserKey = ["get", "embedded", "url"];

export const getEmbeddedUrlOptions = (
  query: GetEmbeddedUrlRequest,
  options?: Partial<
    Omit<
      UndefinedInitialDataOptions<
        GetEmbeddedUrlResponse,
        unknown,
        GetEmbeddedUrlResponse
      >,
      "queryKey" | "queryFn" | "select"
    >
  >,
) =>
  queryOptions({
    queryKey: [...GetUserKey, query],
    queryFn: () => getEmbeddedUrl(query),
    select: (data) => {
      return data.data;
    },
    retry: false,
    gcTime: Infinity,
    ...options,
  });

export const useGetEmbeddedUrl = (
  query: GetEmbeddedUrlRequest,
  options?: Partial<
    Omit<
      UndefinedInitialDataOptions<
        GetEmbeddedUrlResponse,
        unknown,
        GetEmbeddedUrlResponse
      >,
      "queryKey" | "queryFn" | "select"
    >
  >,
) =>
  useQuery({ ...getEmbeddedUrlOptions(query, options), staleTime: Infinity });
