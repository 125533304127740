"use client";

import { usePathname, useRouter } from "next/navigation";
import Link from "next/link";
import Image from "next/image";
import React, { useEffect } from "react";
import OlynLogo from "/public/static/OLYN.svg";
import {
  cn,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Switch,
  User,
} from "@nextui-org/react";
import Stack from "@icons/Stack";
import Share from "@icons/Share";
import PresentationChart from "@icons/PresentationChart";
import CoinsHand from "@icons/CoinsHand";
import Settings from "@icons/Settings";
import { MyButton } from "./Button";
import { userLogout } from "@utils/userLogout";
import Logout from "@icons/Logout";
import DefaultAvatar from "@icons/DefaultAvatar";
import { SvgIconProps } from "@icons/SvgIcon";
import Login from "@icons/Login";
import { getItem } from "@utils/localStorage";
import { useAuth } from "@context/Auth";
import Typography from "./Typography";
import Transactions from "@icons/Transactions";
import UploadProgress from "@components/General/UplaodProgress";
import { Roles } from "@enums/Roles";
import { useGetEmbeddedUrl } from "@features/embedded/services/get-embedded-url";
import { getMetaBaseUrl } from "@features/embedded/utils/get-url";

const links: {
  roles?: Roles[];
  position: "start" | "middle" | "end";
  title: string;
  url?: string;
  click?: VoidFunction;
  mobile?: boolean;
  condition?: () => boolean;
  icon?: React.FunctionComponent<SvgIconProps>;
  isExternal?: boolean;
}[] = [
  {
    roles: [Roles["creator.admin"], Roles["md.admin"]],
    position: "middle",
    title: "Home",
    url: "/",
    icon: Stack,
  },
  {
    roles: [Roles["creator.admin"]],
    position: "middle",
    title: "Partners",
    url: "/partners",
    icon: Share,
  },
  {
    roles: [Roles["creator.admin"], Roles["md.admin"]],
    position: "middle",
    title: "Detailed Metrics",
    url: "/analytics",
    icon: PresentationChart,
  },
  {
    roles: [Roles["creator.admin"]],
    position: "middle",
    title: "Viewer Experience",
    url: "/user-experience",
    icon: PresentationChart,
  },
  {
    roles: [Roles["creator.admin"]],
    position: "middle",
    title: "Transactions",
    url: "/transactions",
    icon: Transactions,
  },
  {
    roles: [Roles["creator.admin"], Roles["md.admin"]],
    position: "middle",
    title: "Earnings",
    url: "/earnings",
    icon: CoinsHand,
  },
  /*  {
    roles: [Roles["creator.admin"], Roles["md.admin"]],
    position: "middle",
    title: "Settings",
    url: "/settings",
    icon: Settings,
  }, */
  {
    position: "end",
    condition: () => !Boolean(getItem("access_token")),
    mobile: false,
    title: "Log In",
    url: "/login",
    icon: Login,
  },
  {
    roles: [Roles["creator.admin"], Roles["md.admin"]],
    position: "end",
    condition: () => Boolean(getItem("access_token")),
    title: "Log Out",
    click: userLogout,
    icon: Logout,
  },
];

const SideNavigation = () => {
  const { user, role, logout } = useAuth();
  const pathname = usePathname();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [navigationLinks, setLinks] = React.useState(links);
  const router = useRouter();

  const { data, status } = useGetEmbeddedUrl({ content_id: "" });

  useEffect(() => {
    if (data) {
      setLinks((state) => {
        return state.reduce(
          (acc, item) => {
            if (item.url === "/analytics") {
              acc.push({
                ...item,
                isExternal: true,
                url: getMetaBaseUrl(data.url, { tab: "analytics" }),
              });
            } else if (item.url === "/transactions") {
              acc.push({
                ...item,
                isExternal: true,
                url: getMetaBaseUrl(data.url, { tab: "transactions" }),
              });
            } else if (item.url === "/earnings") {
              acc.push({
                ...item,
                isExternal: true,
                url: getMetaBaseUrl(data.url, { tab: "earnings" }),
              });
            } else {
              acc.push(item);
            }
            return acc;
          },
          [] as typeof links,
        );
      });
    }
  }, [data]);

  return (
    <Navbar
      isBordered
      isMenuOpen={isMenuOpen}
      shouldHideOnScroll
      height="var(--sidebar-mobile-height)"
      onMenuOpenChange={setIsMenuOpen}
      classNames={{
        base: "sm:absolute sm:float-start h-header-mobile sm:h-dynamic-screen w-full sm:w-sideBar-mobile sm:hover:w-sideBar overflow-hidden transition-all delay-100 md:w-sideBar sm:px-2 md:px-[var(--media-spacing-16)] sm:pt-[var(--media-spacing-28)] sm:pb-[var(--media-spacing-24)] bg-[rgb(var(--media-gray-100))] flex-col items-start border-0 border-r-1 border-[rgb(var(--media-gray-200))]",
        wrapper:
          "h-header sm:flex-col sm:items-start sm:p-0 h-full sm:w-[calc(var(--sidebar-width)_-_var(--media-spacing-16)_*_2)] gap-0",
        content: "sm:flex-col sm:items-stretch sm:!gap-2 sm:w-full",
        brand: "shrink-0 grow-0",
      }}
    >
      <NavbarContent className="sm:hidden" justify="start">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        />
      </NavbarContent>
      <NavbarContent className="sm:hidden" justify="center">
        <NavbarBrand className="basis-[100px]">
          <Link href="/">
            <Image height={12.3} src={OlynLogo} alt="Olyn logo" />
          </Link>
        </NavbarBrand>
      </NavbarContent>
      <NavbarContent
        className="hidden h-auto shrink-0 grow-0 sm:flex"
        justify="center"
      >
        <NavbarBrand>
          <Link
            className="mb-4 px-[var(--media-spacing-12)] sm:mb-6 lg:mb-7 xl:mb-8"
            href="/"
          >
            <Image height={12.3} src={OlynLogo} alt="Olyn logo" />
          </Link>
        </NavbarBrand>
      </NavbarContent>
      <NavbarContent className="hidden gap-4 sm:flex" justify="start">
        {navigationLinks.map(
          ({
            condition = () => true,
            position,
            roles,
            title,
            url,
            click,
            isExternal,
            icon: Icon,
          }) => {
            if (
              position !== "middle" ||
              !condition?.() ||
              !roles?.includes(role!)
            )
              return;

            const props: Parameters<typeof MyButton>["0"] = {
              color: pathname === url ? "activeNavLink" : "navLink",
            };

            if (Icon) {
              props["startContent"] = (
                <Icon
                  className={cn({
                    ["text-[rgb(var(--media-gray-400))]"]: pathname !== url,
                    ["text-[rgb(var(--media-gray-800))]"]: pathname === url,
                  })}
                  color="currentColor"
                  size={20}
                />
              );
            }

            if (url) {
              props["as"] = Link;
              props["href"] = url;
              props["target"] = isExternal ? "_blank" : "_self";
              props["rel"] = isExternal ? "noopener noreferrer" : undefined;
            }

            if (click) {
              props["onPress"] = click;
            }

            return (
              <NavbarItem key={url ?? title} isActive={pathname === url}>
                <MyButton
                  fullWidth
                  radius="sm"
                  className="justify-start"
                  {...props}
                >
                  {title}
                </MyButton>
              </NavbarItem>
            );
          },
        )}
      </NavbarContent>
      <NavbarContent justify="end">
        {navigationLinks.map(
          ({
            condition = () => true,
            position,
            title,
            url,
            click,
            roles,
            icon: Icon,
            isExternal,
          }) => {
            if (
              position !== "end" ||
              !condition?.() ||
              (roles && !roles.includes(role!))
            )
              return;

            const props: Parameters<typeof MyButton>["0"] = {
              color: pathname === url ? "activeNavLink" : "navLink",
            };

            if (Icon) {
              props["startContent"] = (
                <Icon
                  className={cn({
                    ["text-[rgb(var(--media-gray-400))]"]: pathname !== url,
                    ["text-[rgb(var(--media-gray-800))]"]: pathname === url,
                  })}
                  color="currentColor"
                  size={20}
                />
              );
            }

            if (url) {
              props["as"] = Link;
              props["href"] = url;
              props["target"] = isExternal ? "_blank" : "_self";
              props["rel"] = isExternal ? "noopener noreferrer" : undefined;
            }

            if (click) {
              props["onPress"] = click;
            }

            if (title === "Log Out") {
              props["onPress"] = logout;
            }

            if (title === "Log In") {
              props["onPress"] = () => {
                window
                  ? window.location.replace("/login")
                  : router.replace("/login");
              };
            }

            return (
              <NavbarItem
                key={url ?? title}
                className="hidden sm:flex"
                isActive={pathname === url}
              >
                <MyButton
                  fullWidth
                  radius="sm"
                  className="justify-start"
                  {...props}
                >
                  {title}
                </MyButton>
              </NavbarItem>
            );
          },
        )}
        <UploadProgress />
        <NavbarItem>
          <User
            name="Account"
            description={user?.email ?? ""}
            classNames={{
              base: "sm:px-3 sm:mt-4 w-full justify-start gap-2",
              wrapper: "max-w-[calc(100%_-_28px_-_0.5rem)]",
              name: "max-w-full text-[rgb(var(--media-gray-500))] typography-body-xs-medium",
              description:
                "max-w-full text-[rgb(var(--media-gray-700))] typography-body-xs-medium truncate",
            }}
            avatarProps={{
              src: "",
              classNames: {
                base: "shrink-0 bg-transparent sm:w-5 sm:h-5 md:w-7 md:h-7",
              },
              fallback: (
                <DefaultAvatar
                  size={28}
                  className={"text-[rgb(var(--media-gray-400))]"}
                  color="currentColor"
                />
              ),
            }}
          />
        </NavbarItem>
      </NavbarContent>
      {/* mobile */}
      <NavbarMenu>
        {navigationLinks.map(
          ({
            condition = () => true,
            mobile = true,
            title,
            roles,
            url,
            click,
            icon: Icon,
            isExternal,
          }) => {
            if (!mobile || !condition?.() || !roles?.includes(role!)) return;

            const props: Parameters<typeof MyButton>["0"] = {
              variant: pathname === url ? "bordered" : "ghost",
            };

            if (Icon) {
              props["startContent"] = (
                <Icon
                  className={cn({
                    ["text-[rgb(var(--media-gray-400))]"]: pathname !== url,
                    ["text-[rgb(var(--media-gray-800))]"]: pathname === url,
                  })}
                  color="currentColor"
                  size={20}
                />
              );
            }

            if (url) {
              props["as"] = Link;
              props["href"] = url;
              props["target"] = isExternal ? "_blank" : "_self";
              props["rel"] = isExternal ? "noopener noreferrer" : undefined;
            }

            if (click) {
              props["onPress"] = click;
            }

            if (title === "Log Out") {
              props["onPress"] = logout;
            }

            return (
              <NavbarMenuItem key={url ?? title}>
                <MyButton
                  fullWidth
                  color="navLink"
                  className="justify-start"
                  size="lg"
                  {...props}
                >
                  {title}
                </MyButton>
              </NavbarMenuItem>
            );
          },
        )}
      </NavbarMenu>
    </Navbar>
  );
};

export default SideNavigation;
