const tabs = {
  analytics: "92-link-activity",
  transactions: "90-transactions",
  earnings: "91-transactions-history",
} as const;

export const getMetaBaseUrl = (
  url: string,
  options?: { tab: keyof typeof tabs; email?: string[]; content_id?: string[] },
) => {
  const urlObj = new URL(url);

  if (options) {
    if (options.tab) {
      urlObj.searchParams.set("tab", tabs[options.tab]);
    }

    if (options.email) {
      options.email.forEach((email) => {
        urlObj.searchParams.set("attribution_email", email);
      });
    }

    if (options.content_id) {
      options.content_id.forEach((id) => {
        urlObj.searchParams.set("root_content_id", id);
      });
    }
  }

  return urlObj.toString();
};
